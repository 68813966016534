import React from "react"
import { graphql } from "gatsby"
import { MDXProvider } from "@mdx-js/react"
import { MDXRenderer } from "gatsby-plugin-mdx"
import { Link } from "gatsby"
import Img from "gatsby-image"

import SEO from "../components/seo"
import GcLayout from "../components/GcLayout"

const shortcodes = { Link } // Provide common components here

export default function PageTemplate({ data: { mdx } }) {
  let featuredImg
  try {
    featuredImg = (
      <Img
        className="w-full sm:w-1/2 xl:w-1/4 rounded box-border bg-gray-100 md:transition duration-1000 ease-in-out md:transform hover:-translate-y-1 hover:scale-150 hover:shadow-xl z-50"
        fluid={mdx.frontmatter.featuredImage.childImageSharp.fluid}
      />
    )
  } catch (e) {
    featuredImg = <></>
  }
  return (
    <GcLayout>
      <SEO title={mdx.frontmatter.title}/>
      <div className="blog-post">
        <header className="flex flex-row items-center justify-center flex-wrap mb-4">
          <aside className="w-full bg-gray-200 pt-3 shadow px-1 my-2">
            <h1 className="text-4xl md:text-6xl font-bold text-center">
              {mdx.frontmatter.title}
            </h1>
            <h4 className="text-gray-600 text-center">{mdx.frontmatter.date}</h4>
          </aside>
          {featuredImg}
        </header>
        <main className="container mx-auto px-2 md:px-0">
          <MDXProvider components={shortcodes}>
            <MDXRenderer>{mdx.body}</MDXRenderer>
          </MDXProvider>
        </main>
      </div>
    </GcLayout>
  )
}

export const pageQuery = graphql`
  query BlogPostQuery($id: String) {
    mdx(id: { eq: $id }) {
      id
      body
      frontmatter {
        date(formatString: "DD MMMM, YYYY")
        title
        featuredImage {
          childImageSharp {
            fluid(maxWidth: 800) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`
